.logo {
    position: relative;
    // max-height: 100%;
    // max-width: 100%;
    // max-width: 50vw;
    max-height: 90%;
    // margin-left: 2vw;
    @media screen and (max-width: 768px) {
        padding-left: 20px;
    }
}

.logo:hover {
    cursor: pointer;
}