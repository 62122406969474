.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background: linear-gradient(45deg, #ffd700, #ccb12c, #c49d00, #ffd700);
    background-size: 400% 400%;
    animation: gradientShift 10s linear infinite;  /* Slower animation */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 350px;
    max-height: 0px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    font-size: 14px;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -175px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.5s, max-height 2s;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -webkit-text-fill-color: initial;
    overflow: hidden;
}

.tooltip:hover .tooltiptext {
    max-height: 200px;
    visibility: visible;
    opacity: 1;
}

@keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
