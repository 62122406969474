// :root {
//     --header-height: 5vh;
//     @media screen and (max-width: 1200px) {
//         --header-height: 10vh;
//     }

//     @media screen and (max-width: 768px) {
//         --header-height: 3vh;
//     }
// }

.hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: aqua;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rotating-hero-text {
    height: 8vw;
    max-width: 80vw;
    width: 80vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes hero-text-animation {
    0% {transform: translateY(300%);}
    100% {transform: translateY(-300%);}
}

.hero-text {
    font-size: 8vw;
    max-width: 100%;
    // margin-top: 5%;
    // margin-bottom: 5%;
    flex-wrap: nowrap;
    color: black;
    text-transform: uppercase;
    animation: hero-text-animation;
    animation-duration: 6s;
    animation-fill-mode: both;
    animation-delay: 0.5s;
}

.hero-video-blur {
    filter: blur(5px);
    // background-color: rgb(54, 54, 54);
    animation-name: video-blur;
    animation-duration: 2s;
    animation-delay: 5s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

@keyframes video-blur {
    0% {
        // background-color: rgb(54, 54, 54);
        filter: blur(5px);
    }
    100% {
        // background-color:  rgb(228, 228, 228);
        filter: blur(0px);
    }
}

.video-bg {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // z-index: -2;
    overflow: hidden;
    transition: all .5s ease;
    clip-path: inset(96px 40px 40px 40px round 40px);
    opacity: .3;
    animation-name: fade-video-in;
    animation-duration: 2s;
    animation-delay: 5s;
    animation-fill-mode: both;
    animation-iteration-count: 1;

    @media screen and (max-width: 768px) {
        clip-path: inset(0px 0px 0px 0px round 0px);
    }
}

// .video-bg video {
//     width: 100%;
//     height: auto;
//     display: block; /* remove extra space below video */
    
// }


.video-bg:hover {
    clip-path: inset(0px 0px 0px 0px round 0px);
}

@keyframes fade-video-in {
    0% {opacity: 0.3};
    100% {opacity: 1};
}

.logo-animation {
    display: block;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation-name: logo-animation-fade-out;
    animation-duration: 8s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    border: none;
}

@keyframes logo-animation-fade-out {
    0% {opacity: 0.0};
    5% {opacity: 1.0};
    85% {opacity: 1.0};
    100% {opacity: 0.0};
}

.home-client-logos {
    padding-left: 12%;
    padding-right: 12%;
    padding-top: 20px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-color: white;
    // transition: max-width 1s;

    img {
        max-width: 200px;
        max-height: 100px;
        filter: grayscale(100%);
        transition: 1s;

        &.invert {
            filter: invert(100%);
        }
    }

    img:hover {
        opacity: 70%;
        max-width: 210px;
        max-height: 120px;
    }
}

.home-work {
    height: auto;
    background-color: white;
}

.subtitle {
    display: flex;
    justify-content: center;
    background-color: white;
    gap: 0px 20px;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    h2 {
        font-size: 2rem;
        line-height: 2rem;
        color: black;
    }

    h2:hover {
        cursor: pointer;
        color: grey;
    }
}

.gallery {
    // padding-top: calc(var(--header-height) * 2);
    // margin-bottom: calc(var(--header-height) * -1);
    // padding-left: 5%;
    // padding-right: 5%;
    // padding-left: 20px;
    padding-bottom: var(--header-height);
    display: flex;
    // grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 50px;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 500px) {
        // grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
    
}

.galleryItem {
    display: flex;
    position: relative;
    // width: 100%;
    width: 533px;
    height: 300px;

    transition: 1s;
    @media screen and (max-width: 500px) {
        max-width: 90%; 
    }

    
    // flex-shrink: 0;
}

// .galleryItem:hover {
//     // @media screen and (max-width: 500px) {
//     //     width: 100%;
//     // }
//     // width: 622px;
//     // height: 350px;
//     // display: block;
//     // flex-shrink: 0;
//     // max-width: 100%;
//     // max-height: 100%;
// }

.gallery-stack {
    display: flex;
    flex-direction: column;
    width: 100%;
    // gap: 10px;
    height: 100%;
}

.gallery-video {
    // display: block;
    // position: relative;
    // top: 20px;
    // left: 20px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    transition: 0.5s;

    border-radius: 20px;
    overflow: hidden;
    object-fit: cover;

    border: 1 solid;
}

.gallery-video:hover {
    // border:rgb(40, 40, 40) 10px solid;
    box-shadow: #373737 0px 0px 15px;
    // width: 105%;
    // height: 105%;
}

.gallery-title {
    // width: 100%;
    // height: 100%;
    font-size: 30px;
    color: white;
    top: 0px;
    margin-top: 15px;
    // top: -100%;
    // margin-top: -100%;
    left: 20px;
    position: absolute;
}

.galleryMyPosition {
    font-size: 20px;
    color: black;
    margin-top: 10px;
    margin-left: 15px;
}

.gallery-role {
    font-size: 15px;
    color: rgb(91, 91, 91);
    // margin-top: 10px;
   
    margin-right: 5px;
}

.gallery-caption {
    display: flex;
    // margin-top: 10px;
    // margin-left: 15px;
    // margin-right: 15px;
    flex-direction: row;

    // // gap: 20px;
    justify-content: space-between;
}

.gallery-clickThrough {
    margin-top: 10px;
    margin-right: 15px;
}

.work {
    margin-top: var(--header-height);
    // display: flex;
    // justify-content: left;
    // align-items: left;
    // flex-direction: column;
    // padding: 50px;
    // margin-left: 5%;
    // margin-right: 5%;
    margin-left: 5%;
    margin-right: 5%;

    .work-title {
        padding-left: 5%;
        // padding-right: 5%;
        font-size: 50px;
        padding-top: var(--header-height);
    }

    .work-gallery-title {
        margin-left: 5%;
        font-size: 2.5rem;
        padding-left: 20px;
        margin-bottom: 20px;
    }

    .comingSoon {
        font-size: 20px;
        background-color: rgb(242, 242, 242);
        border-radius: 25px;
        padding: 25px;
    }

    // transition: box-shadow 2s ease-in;

    // .comingSoon:hover {
    //     box-shadow: 10px 10px 5px black;
    // }

    
}

.feature {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;

    background-color: rgb(40, 40, 40);
    border-radius: 20px;

    color: rgb(232, 232, 232);
}

.feature-client-logo {
    // max-width: 50px;
    max-height: 50px;
}

.feature-title {
    font-size: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 15px;
    color: rgb(241, 241, 241);
    @media (max-width: 768px) {
        font-size: 30px;
    }
}

.feature-title:hover {
    cursor: pointer;
}

.feature-carrot {
    font-size: 50px;
    height: 25px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s;

    rotate: 90deg;

    &.expanded {
        rotate: 180deg;
    }
}

.feature-body {
    overflow: hidden;
    max-height: 0px;
    transition: max-height 1s ease;
    p {
        font-size: 18px;
    }
    

    &.expanded {
        max-height: 1500px;
    }



    // &.folded {
    //     animation-duration: 1s;
    //     // animation-name: foldFeature;
    //     animation-timing-function: ease;
    //     animation-fill-mode: forwards;
    //     transition: 1s;
    // }

    // &.expanded {
    //     animation-duration: 1s;
    //     animation-name: expandFeature;
    //     animation-timing-function: ease;
    //     animation-fill-mode: forwards;
    //     transition: 1s;
    // }
}

.feature-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.feature-image {
    object-fit:scale-down;
    max-width: 400px;
    max-height: 600px;
    margin: 20px;
}

@keyframes foldFeature {
    from {
        max-height: 1500px;
    }
    to {
        max-height: 0;
    }
}

@keyframes expandFeature {
    from {
        max-height: 0;
    }
    to {
        max-height: 1500px;
    }
}

.about-body {
    margin-top: var(--header-height);
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    margin-left: 20vw;
    margin-right: 20vw;

    h1 {
        font-size: 50px;
    }

    p {
        font-size: 20px;
    }
}


.profile-pic {
    border-radius: 50%;
    width: 125px;
    height: 125px;
    object-fit: cover;
}




.airtable-contact {
    margin-top: var(--header-height);
    background: transparent; 
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.work-type {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    h1 {
        font-size: 50px;
        margin-bottom: 20px;
    }

    .work-type-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        button {
            margin: 10px;
        }
    }
}



