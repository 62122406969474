.centered-banner-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: 100%;
    font-size: 30px;
}

.hero-product-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    max-width: 100%;
    max-height: 100%;

    video {
        clip-path: inset(40px 40px 40px 40px round 40px);
        max-width: 90%;
        // max-width: 1920px;
        // max-height: 1080px;
    }
}

.product-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .rive-play-pause-btn {
        position: relative;
        bottom: 80px;
        right: -40vw;
        transform: translate(-50%, -50%);
    }
}

.code {
    .highlighted-code .tag:hover {
        // Scale up
        transform: scale(1.05);
    }

    .highlighted-code .slash:hover {
        transform: scale(1.05);
    }

    .highlighted-code .brackets:hover {
        transform: scale(1.05);
    }

    .right-bracket:hover {
        transform: scale(1.05);
    }
}
  
.highlighted-code {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    // overflow: hidden;
    // .h1 {
    //     width: 100px;
    //     text-align: left;
    // }
}
  
.highlighted-code .brackets {
    color: #22863a;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}
  
.highlighted-code .tag {
    color: #d73a49;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}
  
  .highlighted-code .slash {
    color: #6f42c1;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Animation for the left bracket */
  .left-bracket {
    animation: moveLeftBracket 2s ease-in-out infinite;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Animation for the right bracket */
  .right-bracket {
    animation: moveRightBracket 2s ease-in-out infinite;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Keyframes for the left bracket moving back and forth */
  @keyframes moveLeftBracket {
    0%, 100% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(0px);
    }
  }
  
  /* Keyframes for the right bracket moving back and forth */
  @keyframes moveRightBracket {
    0%, 100% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(0px);
    }
  }