.header {
    position: fixed;
    top: 0;
    left: 0;
    // padding: 10px;
    // margin-top: -10px;
    // margin-left: -10px;
    // min-height: 50px;
    height: var(--header-height);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    justify-content: space-around;
    // gap: 20vw;
    background-color: white;
    font-size: large;
    z-index: 100;
    opacity: .8;

    a {
        width: 100%;
        max-width: 50vw;
        height: 100%;
        text-decoration: none;
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    a:hover {
        color: grey;
    }

    // @media screen and (max-width: 768px) {
    //     max-height: 50px;
    // }
}