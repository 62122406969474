/* PlayPauseButton Styles */
.play-pause-btn {
    position: absolute; /* Position the button relative to the container */
    bottom: 60px; /* Adjust distance from the bottom */
    right: 60px; /* Adjust distance from the right */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    // transition: bottom right 1s ease;
  }
  
  .play-pause-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);

    // bottom: 20px;
    // right: 20px;
  }
  
  .play-pause-btn svg {
    fill: white;
    transition: fill 0.3s ease;
  }
  
  .play-pause-btn:hover svg {
    fill: #ffcc00;
  }

  .rive-play-pause-btn {
    position: absolute; /* Position the button relative to the container */
    bottom: 60px; /* Adjust distance from the bottom */
    right: 60px; /* Adjust distance from the right */
    // display: inline-flex;
    // justify-content: center;
    // align-items: center;
    width: 50px;
    height: 50px;
    // background-color: rgba(0, 0, 0, 0.6);
    // border-radius: 50%;
    // border: none;
    cursor: pointer;
    // transition: background-color 0.3s ease;
    // transition: bottom right 1s ease;
  }