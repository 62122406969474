.page-body {
    margin-top: var(--header-height);
}


body {
    max-width: 100%;
    width: 100%;
    height: 100%;
    // height: 200vh;
    background-color: rgb(228, 228, 228);
    font-family: 'Karla', sans-serif;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}


a {
    text-decoration: none;
}

.hidden {
    display: none;
}