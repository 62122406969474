@import "https://fonts.googleapis.com/css2?family=Karla:wght@600&display=swap";
.page-body {
  margin-top: var(--header-height);
}

body {
  background-color: #e4e4e4;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Karla, sans-serif;
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

:root {
  --header-height-desktop: 5vh;
  --header-height-tablet: 10vh;
  --header-height-mobile: 3vh;
}

@media screen and (width <= 1200px) {
  :root {
    --header-height: var(--header-height-tablet);
  }
}

@media screen and (width <= 768px) {
  :root {
    --header-height: var(--header-height-mobile);
  }
}

:root {
  --header-height: var(--header-height-desktop);
}

.hero {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.rotating-hero-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 80vw;
  height: 8vw;
  display: flex;
  overflow: hidden;
}

@keyframes hero-text-animation {
  0% {
    transform: translateY(300%);
  }

  100% {
    transform: translateY(-300%);
  }
}

.hero-text {
  color: #000;
  text-transform: uppercase;
  flex-wrap: nowrap;
  max-width: 100%;
  font-size: 8vw;
  animation: 6s .5s both hero-text-animation;
}

.hero-video-blur {
  filter: blur(5px);
  animation-name: video-blur;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 5s;
  animation-fill-mode: both;
}

@keyframes video-blur {
  0% {
    filter: blur(5px);
  }

  100% {
    filter: blur();
  }
}

.video-bg {
  object-fit: cover;
  clip-path: inset(96px 40px 40px round 40px);
  opacity: .3;
  width: 100%;
  height: 100%;
  transition: all .5s;
  animation-name: fade-video-in;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 5s;
  animation-fill-mode: both;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media screen and (width <= 768px) {
  .video-bg {
    clip-path: inset(0);
  }
}

.video-bg:hover {
  clip-path: inset(0);
}

@keyframes fade-video-in {
  0% {
    opacity: .3;
  }

  100% {
    opacity: 1;
  }
}

.logo-animation {
  pointer-events: none;
  border: none;
  width: 100%;
  height: 100%;
  animation-name: logo-animation-fade-out;
  animation-duration: 8s;
  animation-iteration-count: 1;
  animation-delay: .5s;
  animation-fill-mode: both;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes logo-animation-fade-out {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.home-client-logos {
  background-color: #fff;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px 12% 100px;
  display: flex;
}

.home-client-logos img {
  filter: grayscale();
  max-width: 200px;
  max-height: 100px;
  transition: all 1s;
}

.home-client-logos img.invert {
  filter: invert();
}

.home-client-logos img:hover {
  opacity: .7;
  max-width: 210px;
  max-height: 120px;
}

.home-work {
  background-color: #fff;
  height: auto;
}

.subtitle {
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.subtitle h2 {
  color: #000;
  font-size: 2rem;
  line-height: 2rem;
}

.subtitle h2:hover {
  cursor: pointer;
  color: gray;
}

.gallery {
  padding-bottom: var(--header-height);
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  display: flex;
}

.galleryItem {
  width: 533px;
  height: 300px;
  transition: all 1s;
  display: flex;
  position: relative;
}

@media screen and (width <= 500px) {
  .galleryItem {
    max-width: 90%;
  }
}

.gallery-stack {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.gallery-video {
  object-fit: cover;
  border: 1px solid;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  transition: all .5s;
  overflow: hidden;
}

.gallery-video:hover {
  box-shadow: 0 0 15px #373737;
}

.gallery-title {
  color: #fff;
  margin-top: 15px;
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 20px;
}

.galleryMyPosition {
  color: #000;
  margin-top: 10px;
  margin-left: 15px;
  font-size: 20px;
}

.gallery-role {
  color: #5b5b5b;
  margin-right: 5px;
  font-size: 15px;
}

.gallery-caption {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.gallery-clickThrough {
  margin-top: 10px;
  margin-right: 15px;
}

.work {
  margin-top: var(--header-height);
  margin-left: 5%;
  margin-right: 5%;
}

.work .work-title {
  padding-left: 5%;
  padding-top: var(--header-height);
  font-size: 50px;
}

.work .work-gallery-title {
  margin-bottom: 20px;
  margin-left: 5%;
  padding-left: 20px;
  font-size: 2.5rem;
}

.work .comingSoon {
  background-color: #f2f2f2;
  border-radius: 25px;
  padding: 25px;
  font-size: 20px;
}

.feature {
  color: #e8e8e8;
  background-color: #282828;
  border-radius: 20px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 20px 40px;
}

.feature-client-logo {
  max-height: 50px;
}

.feature-title {
  color: #f1f1f1;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 15px;
  font-size: 50px;
  display: flex;
}

@media (width <= 768px) {
  .feature-title {
    font-size: 30px;
  }
}

.feature-title:hover {
  cursor: pointer;
}

.feature-carrot {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-top: 7px;
  font-size: 50px;
  transition: all 1s;
  display: flex;
  rotate: 90deg;
}

.feature-carrot.expanded {
  rotate: 180deg;
}

.feature-body {
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden;
}

.feature-body p {
  font-size: 18px;
}

.feature-body.expanded {
  max-height: 1500px;
}

.feature-images {
  flex-flow: wrap;
  display: flex;
}

.feature-image {
  object-fit: scale-down;
  max-width: 400px;
  max-height: 600px;
  margin: 20px;
}

@keyframes foldFeature {
  from {
    max-height: 1500px;
  }

  to {
    max-height: 0;
  }
}

@keyframes expandFeature {
  from {
    max-height: 0;
  }

  to {
    max-height: 1500px;
  }
}

.about-body {
  margin-top: var(--header-height);
  justify-content: left;
  align-items: left;
  flex-direction: column;
  margin-left: 20vw;
  margin-right: 20vw;
  display: flex;
}

.about-body h1 {
  font-size: 50px;
}

.about-body p {
  font-size: 20px;
}

.profile-pic {
  object-fit: cover;
  border-radius: 50%;
  width: 125px;
  height: 125px;
}

.airtable-contact {
  margin-top: var(--header-height);
  background: none;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.error {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.work-type {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.work-type h1 {
  margin-bottom: 20px;
  font-size: 50px;
}

.work-type .work-type-buttons {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.work-type .work-type-buttons button {
  margin: 10px;
}

.title-text {
  text-align: center;
  margin-top: 150px;
}

.title-text h1 {
  font-size: 100px;
}

@media screen and (width <= 768px) {
  .title-text h1 {
    font-size: 50px;
  }
}

.description-text {
  text-align: left;
  width: 70%;
  margin-bottom: 100px;
  margin-left: 15%;
}

.description-text h2 {
  text-align: left;
}

.description-text .rive-tooltip {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, red, #cc2c2c, #c40000, red);
  -webkit-background-clip: text;
}

h2 {
  text-align: center;
  font-size: 40px;
}

.hero-logo-animation-video {
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  display: flex;
}

.hero-logo-animation-video video {
  clip-path: inset(40px round 40px);
  max-width: 90%;
}

.logo-animation-packages {
  flex-flow: wrap;
  justify-content: center;
  gap: 60px 5%;
  width: 100%;
  margin-right: 5%;
  display: flex;
}

@media screen and (width <= 768px) {
  .logo-animation-packages {
    margin-left: 0%;
  }
}

.logo-animation-package {
  border: 1px solid #000;
  border-radius: 10px;
  flex-direction: column;
  width: 25%;
  padding: 20px;
  display: flex;
}

@media screen and (width <= 768px) {
  .logo-animation-package {
    width: 300px;
  }
}

.logo-animation-package ul {
  padding-right: 20px;
  font-size: 25px;
}

.logo-animation-package li {
  margin-top: 10px;
}

@media screen and (width <= 768px) {
  .logo-animation-package li {
    font-size: 20px;
  }
}

.package-title {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.package-title h2 {
  margin: 0;
  padding: 0;
}

@media screen and (width <= 768px) {
  .package-title h2 {
    font-size: 30px;
  }
}

.package-title .rive {
  width: 200px;
  height: 70px;
  margin-left: -10px;
  padding: 0;
}

@media screen and (width <= 768px) {
  .package-title .rive {
    width: 130px;
  }
}

.border-gradient {
  width: 100%;
  height: 100%;
}

.logo-animation-starter {
  box-shadow: 0 0 10px #00000133, 0 10px 10px #75b3673d;
}

.logo-animation-starter .logo-animation-price {
  color: #75b367;
}

.logo-animation-plus {
  border: 2px solid;
  border-radius: 10px;
  margin: -10px;
  transition: all .5s;
  box-shadow: 0 0 50px #00000133, 0 50px 50px #0000713d;
}

.logo-animation-plus .logo-animation-price {
  color: #0000;
  background: linear-gradient(60deg, #6c49b9, #050583) text;
}

.logo-animation-plus:hover {
  width: 32%;
  margin-bottom: -20px;
}

@media screen and (width <= 768px) {
  .logo-animation-plus:hover {
    width: 350px;
  }
}

.logo-animation-premium {
  box-shadow: 0 0 25px #00000133, 0 25px 25px #b69e163d;
}

.logo-animation-premium .logo-animation-price {
  color: #b69e16;
}

.logo-animation-premium .rive-tooltip {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, red, #cc2c2c, #c40000, red);
  -webkit-background-clip: text;
}

.logo-animation-price {
  font-size: 50px;
}

@media screen and (width <= 768px) {
  .logo-animation-price {
    font-size: 35px;
  }
}

.form-below {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
  display: flex;
}

.form-below span {
  color: #fff;
  clip-path: inset(0 round 10px);
  background: radial-gradient(circle at 0 0, #8295ef, #4c44c4);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px;
  font-size: 20px;
  display: flex;
}

.logo-animation-faq {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
  margin-left: 30%;
  margin-right: 30%;
  display: flex;
}

.faq-group {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.faq-question {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  display: flex;
}

.faq-answer {
  margin-left: 20px;
  font-size: 17px;
}

.tooltip {
  cursor: pointer;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, gold, #ccb12c, #c49d00, gold) 0 0 / 400% 400%;
  -webkit-background-clip: text;
  animation: 10s linear infinite gradientShift;
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 1;
  opacity: 0;
  -webkit-text-fill-color: initial;
  background-color: #555;
  border-radius: 6px;
  width: 350px;
  max-height: 0;
  margin-left: -175px;
  padding: 5px 10px;
  font-size: 14px;
  transition: opacity .5s, max-height 2s;
  position: absolute;
  bottom: 125%;
  left: 50%;
  overflow: hidden;
  box-shadow: 0 0 10px #00000080;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  max-height: 200px;
}

@keyframes gradientShift {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.header {
  height: var(--header-height);
  z-index: 100;
  opacity: .8;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: large;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.header a {
  color: #000;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 50vw;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.header a:hover {
  color: gray;
}

.logo {
  max-height: 90%;
  position: relative;
}

@media screen and (width <= 768px) {
  .logo {
    padding-left: 20px;
  }
}

.logo:hover {
  cursor: pointer;
}

.navigation {
  text-transform: uppercase;
  flex-direction: row;
  align-items: center;
  max-width: 50vw;
  margin-right: 2vw;
  display: flex;
}

.navigation.thin {
  top: var(--header-height);
  background-color: #fff;
  flex-direction: column;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  transition: margin-top 1s;
  position: absolute;
  left: 70vw;
}

.navigation.thin.offscreen {
  margin-top: -640px;
}

.navigation.thinHidden {
  flex-direction: column;
  margin-top: -240px;
}

.navigation .highlighted-code {
  text-transform: none;
  margin-bottom: 0;
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

.navigation .highlighted-code:hover {
  background-color: #d0d0d0;
  border-radius: 15px;
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

.nav-button {
  height: 10px;
  font-size: 1.2rem;
}

@media screen and (width >= 1300px) {
  .nav-button {
    font-size: 1.4rem;
  }
}

@media screen and (width <= 768px) {
  .nav-button {
    font-size: 20px;
  }
}

.nav-button:hover {
  cursor: pointer;
  color: gray;
}

.menu-button {
  cursor: pointer;
  max-height: 90%;
}

.nav-divider {
  background-color: #ccc;
  border: none;
  width: 100%;
  height: 1px;
  margin-top: 10px;
}

.play-pause-btn {
  cursor: pointer;
  background-color: #0009;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  transition: background-color .3s;
  display: inline-flex;
  position: absolute;
  bottom: 60px;
  right: 60px;
}

.play-pause-btn:hover {
  background-color: #000c;
}

.play-pause-btn svg {
  fill: #fff;
  transition: fill .3s;
}

.play-pause-btn:hover svg {
  fill: #fc0;
}

.rive-play-pause-btn {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 60px;
  right: 60px;
}

.centered-banner-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 30px;
  display: flex;
}

.hero-product-video {
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  display: flex;
}

.hero-product-video video {
  clip-path: inset(40px round 40px);
  max-width: 90%;
}

.product-video-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.product-video-container .rive-play-pause-btn {
  position: relative;
  bottom: 80px;
  right: -40vw;
  transform: translate(-50%, -50%);
}

.code .highlighted-code .tag:hover, .code .highlighted-code .slash:hover, .code .highlighted-code .brackets:hover, .code .right-bracket:hover {
  transform: scale(1.05);
}

.highlighted-code {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.highlighted-code .brackets {
  color: #22863a;
  transition: transform .3s ease-in-out;
  transform: scale(1);
}

.highlighted-code .tag {
  color: #d73a49;
  transition: transform .3s ease-in-out;
  transform: scale(1);
}

.highlighted-code .slash {
  color: #6f42c1;
  transition: transform .3s ease-in-out;
  transform: scale(1);
}

.left-bracket {
  transition: transform .3s ease-in-out;
  animation: 2s ease-in-out infinite moveLeftBracket;
  transform: scale(1);
}

.right-bracket {
  transition: transform .3s ease-in-out;
  animation: 2s ease-in-out infinite moveRightBracket;
  transform: scale(1);
}

@keyframes moveLeftBracket {
  0%, 100% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(0);
  }
}

@keyframes moveRightBracket {
  0%, 100% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(0);
  }
}
/*# sourceMappingURL=index.36018283.css.map */
