.title-text {
    margin-top: 150px;
    text-align: center;

    h1 {
        font-size: 100px;
    
        @media screen and (max-width: 768px) {
            font-size: 50px;
        }
    }
}

.description-text {
    text-align: left;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 100px;

    h2 {
        text-align: left;
    }

    .rive-tooltip {
        background: linear-gradient(45deg, #ff0000, #cc2c2c, #c40000, #ff0000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}


h2 {
    font-size: 40px;
    text-align: center;
}

.hero-logo-animation-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    max-width: 100%;
    max-height: 100%;

    video {
        clip-path: inset(40px 40px 40px 40px round 40px);
        max-width: 90%;
        // max-width: 1920px;
        // max-height: 1080px;
    }
}

.logo-animation-packages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // align-items: center;
    width: 100%;
    justify-content: center;
    gap: 5%;
    row-gap: 60px;
    // margin-left: 5%;
    margin-right: 5%;

    @media screen and (max-width: 768px) {
        margin-left: 0%;
    }
}

.logo-animation-package {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    // justify-content: flex-start;
    // margin-top: 20px;
    // margin-bottom: 20px;
    width: 25%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;

    @media screen and (max-width: 768px) {
        width: 300px;
    }

    ul {        
        font-size: 25px;
        padding-right: 20px;
    }

    li {
        margin-top: 10px;
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }
}

.package-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    // justify-content: center;
    // gap: 10px;

    h2 {
        padding: 0px;
        margin: 0px;
        @media screen and (max-width: 768px) {
            font-size: 30px;
        }
    }

    .rive {
        width: 200px;
        height: 70px;
        padding: 0px;
        margin-left: -10px;
        

        @media screen and (max-width: 768px) {
            width: 130px;
        }
    }
}

.border-gradient {
    width: 100%;
    height: 100%;
}

.logo-animation-starter {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0.5, 0.2), 0 10px 10px 0 rgba(117, 179, 103, 0.24);

    .logo-animation-price {
        color: rgb(117, 179, 103);     
    }
}

.logo-animation-plus {
    // Outer Shadow
    box-shadow: 0 0 50px 0 rgba(0, 0, 0.5, 0.2), 0 50px 50px 0 rgba(0, 0, 113, 0.24);

    // display: inline-flex;
    margin: -10px;
    border: 2px solid;
    // padding: 100px;
    // border: solid;
    // border-width: 2px;
    border-radius: 10px;
    // background-image: linear-gradient(white, white), radial-gradient(circle at top left, #f00, #3020ff);
    // background-image: linear-gradient(white, white), linear-gradient(to right, green, gold);
    // border-image-slice: 1;
    // background-image: linear-gradient(white, white), linear-gradient(rgba(0, 0, 0, 0.448), rgb(0, 0, 240));
    // background-origin: border-box;
    // background-clip: content-box, border-box;
    // clip-path: outset(10px 10px 10px 10px round 10px);
    transition: 0.5s;
    .logo-animation-price {
        background: linear-gradient(60deg,rgb(108, 73, 185), rgb(5, 5, 131));   
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}

.logo-animation-plus:hover {
    width: 32%;
    margin-bottom: -20px;
    @media screen and (max-width: 768px) {
        width: 350px;
    }
}

// .logo-animation-price::before{
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     border-radius: 10px; /* Same as the .gradient-border */
//     padding: 2px; /* Adjust based on your border width */
//     background: linear-gradient(rgba(0, 0, 0, 0.5), rgb(0, 0, 240));
//     -webkit-mask: 
//       linear-gradient(#fff 0 0) content-box, 
//       linear-gradient(#fff 0 0);
//     mask: 
//       linear-gradient(#fff 0 0) content-box, 
//       linear-gradient(#fff 0 0);
//     -webkit-mask-composite: xor;
//             mask-composite: exclude; /* Make sure background shows only in border area */
// }

.logo-animation-premium {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0.5, 0.2), 0 25px 25px 0 rgba(182, 158, 22, 0.24);

    .logo-animation-price {
        color: rgb(182, 158, 22);     
    }

    .rive-tooltip {
        background: linear-gradient(45deg, #ff0000, #cc2c2c, #c40000, #ff0000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}


.logo-animation-price {
    // display: inline-block;
    font-size: 50px;
    // text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 35px;
    }
}

.form-below {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 10px;

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: white;
        font-size: 20px;
        padding: 30px;
        // width: 300px;
        // height: 100px;
        background: radial-gradient(circle at top left, rgb(130, 149, 239), #4c44c4);
        clip-path: inset(0px 0px 0px 0px round 10px);
    }

}

.logo-animation-faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-left: 30%;
    margin-right: 30%;
    // max-width: 60%;
    gap: 20px;
}

.faq-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.faq-question {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 20px;
}

.faq-answer {
    font-size: 17px;
    margin-left: 20px;
}

