:root {
    --header-height-desktop: 5vh;
    --header-height-tablet: 10vh;
    --header-height-mobile: 3vh;
}

@media screen and (max-width: 1200px) {
    :root {
        --header-height: var(--header-height-tablet);
    }
}

@media screen and (max-width: 768px) {
    :root {
        --header-height: var(--header-height-mobile);
    }
}

:root {
    --header-height: var(--header-height-desktop);
}