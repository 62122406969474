.navigation {
    // height: 500px;
    // width: 25%;
    max-width: 50vw;
    margin-right: 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    // gap: 10%;
    text-transform: uppercase;

    &.thin {
        flex-direction: column;
        background-color: white;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0px;
        position: absolute;
        top: var(--header-height);
        left: 70vw;

        transition: margin-top 1s;

        &.offscreen {
            margin-top: -640px;
        }
    }

    &.thinHidden {
        flex-direction: column;
        margin-top: -240px;
    }

    .highlighted-code {
        text-transform: none;
        margin-bottom: 0px;
        padding-right: 15px;
        padding-left: 15px;
        margin-left: -15px;
    }

    .highlighted-code:hover {
        background-color: rgb(208, 208, 208);
        border-radius: 15px;
        padding-right: 15px;
        padding-left: 15px;
        margin-left: -15px;
    }
}

.nav-button {
    font-size: 1.2rem;
    height: 10px;
    // line-height: 0.1rem;
    @media screen and (min-width: 1300px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.nav-button:hover {
    cursor: pointer;
    color: grey;
}

.menu-button {
    max-height: 90%;
    cursor: pointer;
}

.nav-divider {
    width: 100%; /* Adjust the width of the divider as needed */
    height: 1px; /* Thin line for the divider */
    background-color: #ccc; /* Light grey color for the divider */
    margin-top: 10px; /* Spacing between items and center the line */
    border: none; /* Remove any default borders */
  }

// .navigation {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//     text-transform: uppercase;
//     transition: transform 0.5s ease-in-out;
//     position: fixed;
//     top: 0;
//     right: 0;
//     height: 100vh;
//     width: 250px;
//     background-color: white;
//     z-index: 200;
//     transform: translateY(-100%); // hide by default

//     &.active {
//         transform: translateY(0%); // show
//     }

//     .nav-button {
//         font-size: 2rem;
//         margin: 10px 0;

//         @media screen and (min-width: 1300px) {
//             font-size: 1.4rem;
//         }

//         @media screen and (max-width: 768px) {
//             font-size: 20px;
//         }
//     }
// }

// .menu-button {
//     font-size: 2rem;
//     cursor: pointer;
//     position: fixed;
//     top: 20px;
//     right: 20px;
//     z-index: 300;

//     @media screen and (max-width: 768px) {
//         font-size: 1.5rem;
//     }
// }